import { atoms } from "#/lib/atoms/atoms";
import cookies from "#/lib/cookies";
import { queryClient } from "#/lib/query";
import { fetchRequest } from "#/lib/stream-api";
import { useSetAtom } from "jotai";
import { useRouter } from "next/router";
import { useCallback } from "react";

export const useOnLogin = () => {
  const router = useRouter();
  const setMid = useSetAtom(atoms.mid);
  const setAccessToken = useSetAtom(atoms.accessToken);
  const setOrgId = useSetAtom(atoms.orgId);

  const onLogin = useCallback(
    async (access_token, refresh_token, skip_redirect?: boolean) => {
      cookies.set(cookies.options.access_token, access_token);
      setAccessToken(access_token);
      cookies.set(cookies.options.refresh_token, refresh_token);
      const orgs = await queryClient.fetchQuery({
        queryKey: ["orgsLazy", access_token],
        staleTime: 0,
        queryFn: () => fetchRequest("/org"),
      });
      cookies.set(cookies.options.org_id, orgs[0]?._id);
      setOrgId(orgs[0]?._id);
      cookies.set(cookies.options.merchant_id, orgs[0]?.merchants[0]?._id);
      setMid(orgs[0]?.merchants[0]?._id);
      if (!skip_redirect) {
        await router.push("/menu");
        return {};
      } else {
        return {
          merchant_id: orgs[0]?.merchants[0]?._id,
        };
      }
    },
    [router, setAccessToken, setMid, setOrgId],
  );

  return {
    onLogin,
  };
};
