import { ThemeContext } from "#/providers/ThemeProvider";
import Loader from "#/ui/standard-loader";
import { ButtonHTMLAttributes, useContext } from "react";
import { twMerge } from "tailwind-merge";

export const Button = ({
  text,
  rootStyles,
  onClick,
  loading,
  left,
  right,
  disabled,
  type,
}: {
  loading?: boolean;
  rootStyles?: any;
  left?: any;
  link?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  right?: any;
  disabled?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
}) => {
  const { foregroundColor } = useContext(ThemeContext);
  return (
    <button
      type={type}
      className={twMerge(
        "px-6 flex-row flex text-center justify-center items-center py-3 max-h-[48px] m-2 whitespace-nowrap text-primary-foreground transition-colors duration-150 bg-primary rounded-xl font-500 text-base focus:shadow-outline hover:bg-card border-[transparent] hover:text-primary border hover:border-primary",
        disabled ? "opacity-50 cursor-default" : "opacity-100 cursor-pointer",
        rootStyles,
      )}
      disabled={disabled || loading}
      {...(onClick && { onClick: onClick })}
    >
      {left}
      {loading ? (
        <div className="w-10">
          <Loader color={foregroundColor} />
        </div>
      ) : (
        text
      )}
      {right}
    </button>
  );
};
