import UnProtectedPage from "#/components/layout/unprotected-page";
import { useOnLogin } from "#/hooks/use-on-login";
import { useTStream } from "#/hooks/use-t-stream";
import { PathProps, withTheme, withThemePaths } from "#/hooks/with-theme";
import { postRequest } from "#/lib/stream-api";
import { ThemeContext } from "#/providers/ThemeProvider";
import { Button } from "#/ui/deprecated-buttons/Button";
import { Input } from "#/ui/standard-input";
import { GetStaticPaths } from "next";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useContext } from "react";
import { useForm } from "react-hook-form";
import useSWRMutation from "swr/mutation";

const LoginHead = () => {
  const theme = useContext(ThemeContext);
  const { t } = useTStream("screens.login");
  return (
    <Head>
      <title>
        {t("Log In")} | {theme.name}
      </title>
    </Head>
  );
};

export default function LoginPage({ theme }) {
  const router = useRouter();
  const email = router?.query?.email;
  const { onLogin } = useOnLogin();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: email || "",
      password: "",
    },
  });

  const { trigger, isMutating } = useSWRMutation("/auth/login", postRequest);

  const onContinue = useCallback(
    async (data: any) => {
      try {
        const res = await trigger(data);
        await onLogin(res.access_token, res.refresh_token);
      } catch (error) {
        console.error(error);
      }
    },
    [trigger, onLogin],
  );

  const { t } = useTStream("screens.login");

  return (
    <UnProtectedPage theme={theme}>
      <LoginHead />
      <form
        className="flex flex-2  h-[100vh] items-center justify-center"
        onSubmit={handleSubmit(onContinue)}
      >
        <div className="flex h-full w-[80%] items-center justify-evenly max-w-[500px] max-h-[450px] flex-col">
          <p className="w-full mb-5 text-5xl text-foreground font-500">
            {t("Login")}
          </p>
          <Input
            title={t("Email")}
            placeholder={t("Email")}
            rootStyles="w-full"
            register={{ ...register("email") }}
          />
          <Input
            title={t("Password")}
            placeholder={t("Password")}
            secure
            rootStyles="w-full"
            register={{ ...register("password") }}
          />
          <Button
            text={t("Continue")}
            rootStyles="w-full"
            loading={isMutating}
          />
          <div className="h-[1px] mb-5 bg-background w-full" />
          <div className="flex flex-row w-full justify-between">
            <Link
              className="w-full text-primary text-sm font-500"
              href="/forgot"
            >
              {t("Can't log in?")}
            </Link>
            <Link
              className="w-full text-primary text-right text-sm font-500"
              href="/register"
            >
              {t("Sign Up")}
            </Link>
          </div>
        </div>
      </form>
    </UnProtectedPage>
  );
}

export const getStaticPaths: GetStaticPaths<PathProps> = withThemePaths(
  async () => ({
    paths: [],
  }),
);

export const getStaticProps = withTheme(async () => ({
  props: {},
}));
