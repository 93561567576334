import _ from "lodash";

export const CapitalizeFirstLetter = (string: string) => {
  if (!string) return;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const RemoveSpecialChars = (str) =>
  str.replace(/[^a-zA-Z ]/g, "").toLowerCase();

export function ValidPassword(password) {
  return password?.length >= 8 && password?.length <= 40;
}

export const capitalizeEachWord = (s?: string | null) => {
  if (!s) return "";
  return s
    ?.split("_")
    .map((s) => _.capitalize(s))
    .join(" ");
};
