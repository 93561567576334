import { useCohere } from "#/hooks/use-cohere";
import { useLoggedIn } from "#/hooks/use-logged-in";
import { ThemeProvider } from "#/providers/ThemeProvider";
import { Theme } from "#/types.ts/other";
import { useRouter } from "next/router";
import { ReactNode, useEffect } from "react";

export default function UnProtectedPage({
  children,
  skipRedirect,
  theme,
}: {
  children: ReactNode | ReactNode[];
  skipRedirect?: boolean;
  theme: Theme;
}) {
  const isLoggedIn = useLoggedIn();
  const router = useRouter();
  useCohere();

  useEffect(() => {
    if (isLoggedIn && !skipRedirect) {
      router.replace({ pathname: "/menu" });
    }
  }, [isLoggedIn, skipRedirect, router]);

  return (
    <ThemeProvider theme={theme}>
      <div id="main_nprog" className="overflow-y-scroll flex bg-card">
        <div className="h-[100vh] flex-col flex w-[100vw] flex-1 overflow-hidden">
          <div className="flex flex-row itesm-center justify-center flex-1">
            <div className="flex-1 hidden md:flex">
              <img
                src="/assets/landing.webp"
                className="flex flex-1 object-cover"
              />
            </div>
            <div className="flex flex-1 flex-col">{children}</div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
